.scaled_img {
  max-width: 50%;
  max-height: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.scaled_panel {
  max-width: 50%;
  max-height: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.fantasy {
  font-family: 'Fantasy';
}

.navbar .navbar-nav .nav-link {
  color: #000000;
  font-size: 1.1em;
}

.underlined {
  text-decoration: underline;
}

.navbar .navbar-nav .nav-link:hover {
  color: #808080;
}

.carouselsize {
  max-width: 70%;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subcentered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.brandfont {
  margin-top: 1%;
  margin-left: 1%;
  margin-bottom: -2.5%;
  font-family: Luminari;
  font-size: 3vw;
  justify-content: center;
  display: flex;
  font-weight: bold;
}

.subbrandfont {
  margin-bottom: 1%;
  margin-top: 3%;
  margin-left: 1%;
  font-family: Garamond;
  font-size: 2vw;
  justify-content: center;
  display: flex;
  font-weight: bold;
}



.navfont {
  font-size: 100%;
  font-family: Garamond;
  font-weight: bold;
  max-width: 90%;
  justify-content: space-around;
  font-size: 1.75vw;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 10px;
}


.uparrow {
  max-width: 10px;
  max-height: 10px;
}


.navborder {
  border-right: 2px solid #c4c4c4;
}

.mainfont {
  font-weight: bold;
  font-family: Luminari;
  margin-left: 6%;
  font-size: 2vw;
}

.galleryfont {
  font-weight: bold;
  font-size: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 6%;
  /* font-size: 100%; */
  opacity: 0.9;
}

.cpw {
  margin-left: 1%;
  margin-right: -2%;
}

.centeredcol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 100%;
}

.cardstyle {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 2%;
  margin-right: 2%;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.footer {
  padding: 20px;
  text-align: right;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icons a {
  margin-left: 10px;
  text-decoration: none;
  color: #333;
  font-size: 1.5em;
}

.footerbrand {
  font-weight: bold;
  margin-left: 2%;
  font-family: Garamond;
  white-space: nowrap;

}

.footersocial {
  justify-content: space-around;
}

.padded-left {
  margin-left: 1%;
}

.padded-right {
  margin-right: 1%;
}

.padded-top {
  margin-top: 1%;
}

.padded-bottom {
  margin-bottom: 1%;
}

.padded-top-bot {
  margin-top: 3%;
  margin-bottom: 3%;
}

.carouselposition {
  margin-left: 7%;
}

.logoposition {
  margin-left: 13%;
}

.bold {
  font-weight: 100;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.content {
  max-width: 800px;
  padding: 20px;
}

.historyBody {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  text-align: center;
  font-family: Arial, sans-serif;
}

.historyContent {
  max-width: 800px;
  padding: 20px;
}

.maxheight {
  max-height: 50%;
}

.imageSize {
  width: 100%;
  height: 100%;
}

.commissionForm {
  max-width: 80%;
  justify-content: start;
}

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  /* This centers the form horizontally */
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

.buttonContact {
  padding: 12px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.loginButtonSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}



.aboutfont {
  margin-bottom: 1%;
  margin-top: 1%;
  margin-left: 1%;
  font-family: Garamond;
  font-size: 200%;
  justify-content: center;
  display: flex;
}


.grad {
  background-image: linear-gradient(to bottom, rgba(240, 90, 90, 0.644), rgba(90, 155, 216, 0.863));
}

.centeredAbout {
  justify-content: center;
  text-align: center;
  max-width: 100%;
}

.padded-right5 {
  margin-right: 5%;
}

.product-image {
  width: 100%;
  height: 100%;
  max-width: 300px; /* Adjust the max-width as needed */
  max-height: 300px; /* Adjust the max-height as needed */
  object-fit: cover; /* Ensures the image covers the area without distortion */
}